<!-- 订单评价 -->
<template>
	<div class="goEvalute">
		<div class="label">
			<div class="label1">
				<p v-for="(item,k) in title" @click="active(k,item)" :class="{active:index == k}">{{item.name}}</p>
			</div>
			<div class="comprehensive">
				<p>综合评分:</p>
				<van-rate v-model="value" />
			</div>
			<div class="textarea">
				<textarea name="" id="" placeholder="请写出你的评价"></textarea>
			</div>
			<div class="image">
				<van-field name="uploader">
					<template #input>
						<van-uploader :after-read="afterRead" />
					</template>
				</van-field>
			</div>
			<div class="btn">
				<button>提交</button>
			</div>
			<div class="btn">
				<button>提交</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				index: -1,
				title: [{
						name: "干净整洁"
					},
					{
						name: "房间大"
					},
					{
						name: "性价比高"
					},
					{
						name: "价格实惠"
					},
					{
						name: "位置好"
					}
				],
				value: 3,
				userHead: [{
					url: ''
				}],
			}
		},
		methods: {
			active(k, item) {
				console.log(k, item)
				this.index = k;
			},
			afterRead(file) {
				// 此时可以自行将文件上传至服务器
				console.log(file);
			},
		}
	}
</script>

<style lang="less" scoped>
	.active {
		color: #FC6228;
	}

	.goEvalute {
		background-color: #FFFFFF;
		height: 100%;

		.label {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.label1 {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 90%;
				font-size: 13px;

				p {
					width: 67px;
					height: 25px;
					line-height: 25px;
					text-align: center;
					border: 1px solid #EBEBEB;
					border-radius: 2px;
				}

			}

			.comprehensive {
				width: 90%;
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #1A1A1A;
				display: flex;
				align-items: center;
			}

			.textarea {
				width: 90%;
				height: 109px;
				font-size: 16px;

				textarea {
					border: none;
					width: 100%;
					height: 100%;
					background: #F6F6F6;
				}
			}

			.image {
				width: 90%;
			}
			.btn{
				width: 90%;
				font-size: 16px;
				button{
					width: 100%;
					height: 37px;
					background: #FC6228;
					border-radius: 5px;
					border: none;
					color: #FFFFFF;
				}
			}
		}
	}
</style>
